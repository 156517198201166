import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week `}</em></p>
    <p>{`DB Bench Press 3×12\\@60% 1RM`}</p>
    <p>{`DB Rows 3×12\\@60% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`1 Mile Assault Bike`}</p>
    <p>{`80 Situps`}</p>
    <p>{`60 Burpees`}</p>
    <p>{`40 KB SDHP (53/35)`}</p>
    <p>{`20 Ball Slams (30/20)`}</p>
    <p>{`100 Double Unders`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      